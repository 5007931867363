@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Poppins', sans-serif;
  width: 100vw;
  overflow-x: hidden;
}
body.modal-open {
  padding: 0 !important;
}

#root {
  max-width: 100vw;
  min-height: 100vh;
}

.react-calendar {
  width: 100%;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
}

.masthead {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.masthead h1.ui.header {
  font-size: 4em;
  font-weight: normal;
}

.masthead h2 {
  font-size: 36px;
  font-weight: 400;
}

.react-datepicker-wrapper {
  width: 100%;
}

.button {
  display: inline-block;
  padding: 0.5em 1.5em;
  border-radius: 2em;
  background-color: #0bc268;
  color: #fff;
  position: relative;
  overflow: hidden;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.btn::after,
.btn::before,
a::before,
a,
.btn,
input.btn {
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
}

a:hover .youtube {
  opacity: 0.8;
}

.animation-svg,
svg *,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4em;
  font-size: 2.2em;
  font-weight: 600;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.8em;
}

h4 {
  font-size: 1.6em;
}

h5 {
  font-size: 1.4em;
}

h6 {
  font-size: 1.2em;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
}

.color-green-dark {
  color: #007666;
}

.color-green {
  color: #0bc268;
}

.box-shadow {
  -webkit-box-shadow: 0 12px 30px #0000001a;
  box-shadow: 0 12px 30px #0000001a;
}

body {
  background-color: #fff;
  font-size: 16px;
  overflow-x: hidden;
  font-weight: 500;
  position: relative;
  color: #464646;
  height: auto;
}

.btn {
  display: inline-block;
  padding: 0.5em 1.5em;
  border-radius: 2em;
  background-color: #0bc268;
  color: #fff;
  position: relative;
  overflow: hidden;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.btn::after {
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0bc268;
  z-index: -1;
  position: absolute;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.btn:hover::after {
  left: -101%;
}

.btn:hover {
  color: #fff;
  text-decoration: none;
  background-color: #007666;
  border-color: #007666;
}

a.btn {
  color: #fff;
}

.btn {
  border-color: #0bc268;
}
.btn:focus,
.btn:active {
  outline: 0 !important;
  box-shadow: none !important;
  border: none !important;
}
.btn-full-width {
  width: 100%;
}
.btn.btn-sidebar-rounded {
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}
.btn i {
  color: #fff;
}

.btn.btn-white {
  background-color: #0bc268;
  border: 1px solid #0bc268;
  color: #0bc268;
}

.btn.btn-white::after {
  background-color: #fff;
}

.btn.btn-white:hover {
  color: #fff;
}

.btn.btn-white i {
  color: #0bc268;
}

.btn.btn-white:hover i {
  color: #fff;
}

.btn.btn-second {
  background-color: #0bc268;
  border: 1px solid #007666;
  color: #fff;
}

.btn.btn-second::after {
  background-color: #007666;
}

.btn.btn-second:hover {
  color: #fff;
}

.btn.btn-second i {
  color: #fff;
}

.btn.btn-second:hover i {
  color: #fff;
}

.btn.btn-white.action_btn span {
  font-weight: 500;
}

.btn-mini {
  padding: 0.375rem 1rem;
}

.btn.btn-orange {
  background-color: #fb7a32;
  border: 1px solid #fb7a32;
  color: white;
}

.btn.btn-orange::after {
  background-color: #fb7a32;
}

.btn.btn-orange:hover {
  color: #fff;
}

.btn.btn-orange i {
  color: #fb7a32;
}

.btn.btn-orange:hover i {
  color: #fff;
}

.tile {
  -webkit-box-shadow: 0 12px 30px #0000001a;
  box-shadow: 0 12px 30px #0000001a;
  border-radius: 15px;
  background-color: #fff;
  overflow-x: hidden;
  margin-top: 1em;
}

.tile .tile-header {
  background-color: #167760;
  padding: 1em;
  /* border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;*/
  color: #fff;
}

.tile .card-filters {
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  height: auto;
}

.tile .card-filters div {
  float: none;
}

.modal-content {
  -webkit-box-shadow: 0 12px 30px #0000001a;
  box-shadow: 0 12px 30px #0000001a;
  border-radius: 36px;
  background-color: #fff;
}

.list-row {
  padding: 20px 10px;
  border-bottom: 1px solid #b7bcc5;
  font-weight: 400;
  background-color: #fff;
  vertical-align: middle;
  list-style: none;
}

header h1 {
  color: #414957;
}

.background-green {
  background-color: #0bc268;
  color: #fff;
}

.background-gray {
  background-color: #f7f7f7;
  color: #414957;
}

.tile .nau_tile_border {
  -webkit-box-shadow: 0 12px 30px #0000001a;
  box-shadow: 0 12px 30px #0000001a;
  border-radius: 15px;
  background-color: #fff;
  overflow-x: hidden;
  margin-top: 2em;
  border: 2px solid #0bc268;
}

.tile .nau_tile_border .card-body {
  border: none;
  border-top: 1px solid #e3e6f0;
}
@media (max-width: 767px) {
  .tile .nau_tile_border {
    max-width: 100% !important;
  }
  .mobile-margin-sales-process-tile {
    margin-right: -3em;
    margin-left: -2em;
  }
}
/* #calendar-event-list div {
    font-size: 14px;
    color: #464646;
} */
#calendar-event-list b {
  font-weight: 400;
  font-size: 14px;
  color: #464646;
}

#contact-data {
  padding: 12px;
  border-radius: 2em;
  border: 1px solid #e3e6f0;
  margin-top: 10px;
  margin-bottom: 15px;
}

#pagination input,
select {
  border: 1px solid #c7d3d0;
  color: #333;
  border-radius: 1em;
  outline: none;
  padding: 3px;
  margin: 0 1px;
}

.form-control {
  border-color: #c7d3d0;
  color: #333;
  border-radius: 1em;
}

.page-number {
  width: 24px;
  height: 24px;
  margin: 0 1px;
  border-radius: 1em;
}

#pagination #Limit {
  width: 45px;
}

#pagination .page-number.active {
  font-weight: 700;
}

#calendar-event-list .warning,
#calendar-event-list .warning b {
  color: red;
}

#calendar-event-list .warning-info {
  color: red;
  font-size: 10px;
}

@media (max-width: 767px) {
  .page-numbers {
    width: 100%;
  }
}

.alert-box .alert {
  border: 1px solid #b7bcc5;
  border-top-left-radius: 36px;
  border-bottom-left-radius: 36px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 20px;
}

.alert-box .alert:after {
  content: '';
  display: block;
  height: calc(100% + 1px);
  width: 12px;
  position: absolute;
  top: -1px;
  right: -1px;
}

.alert-box .alert-red:after {
  background: #e41717;
}

.alert-box .alert-orange:after {
  background: #ff7615;
}

.alert-box .alert-yellow:after {
  background: #ffdf00;
}

.alert .btn-red {
  color: #ce1414;
  cursor: pointer;
}

.bootstrap-select .btn {
  background: none;
}

.bootstrap-select .btn:before,
.bootstrap-select .btn:after {
  background: none;
  display: none;
}

#sales-process-tile .dropdown.bootstrap-select {
  border: none;
}

#sales-process-tile .filter-option-inner-inner {
  color: #333;
}

.alert .btn-green {
  color: #0bc268;
  cursor: pointer;
}

.rks-overflow-tile {
  overflow: auto;
  max-height: 200px;
}

.rks-customers-overflow {
  overflow: auto;
  max-height: 300px;
}

.overlay-rks {
  opacity: 35%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: #222;
}

.loader-rks-full-screen {
  position: absolute;
  top: 50%;
  left: 50%;
}

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0bc268;
  flex-direction: column;
  color: #eaeaea;
  font-size: 14px;
  z-index: 1050;
}

.loading-container-round-bottom-corners-modal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0bc268;
  flex-direction: column;
  color: #eaeaea;
  font-size: 14px;
  z-index: 1000;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 0 0 36px 36px;
}

.loading-container-round-all-corners-modal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0bc268;
  flex-direction: column;
  color: #eaeaea;
  font-size: 14px;
  z-index: 1000;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 36px;
}

.spinner-border {
  color: #ffffff77;
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  width: 200px;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  transition: all;
  transition-duration: 0.5s;
  overflow-x: hidden;
}
#sidebar-wrapper {
  min-height: 100vh !important;
  margin-left: -1rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  width: 100%;
}
.sidebar.toggled {
  width: 80px;
}
.sidebar .nav-item {
  width: 100%;
}
.sidebar a.sidebar-link {
  font-size: 14px;
  font-weight: 600;
  color: #464646;
  display: flex;
  align-items: center;
  transition: all;
  transition-duration: 0.5s;
}
.sidebar.toggled a.sidebar-link {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all;
  transition-duration: 0.5s;
}
a.sidebar-link .sidebar-link-text {
  padding-left: 5px;
  transition: all;
  transition-duration: 0.5s;
}
.sidebar.toggled a.sidebar-link .sidebar-link-text {
  padding-left: 0;
  font-size: 10px;
}
a.sidebar-link svg {
  color: #0bc268;
}
a.sidebar-link:hover {
  font-size: 14px;
  font-weight: 600;
  color: #0bc268;
}
.logo {
  max-height: 85px;
  margin-left: 10px;
  margin-right: 10px;
  width: calc(100% - 20px);
}
.institution-select-item {
  background: #fff;
  padding: 1em;
  margin: 1em;
  font-size: 14px;
  font-weight: 600;
  border-radius: 2em;
  cursor: pointer;
  -webkit-box-shadow: 0 6px 12px #00000015;
  box-shadow: 0 6px 12px #00000015;
  width: 100%;
  max-width: 480px;
  transition: all;
  transition-duration: 0.3s;
}
.institution-select-item:hover {
  background: #f7f7f7;
  -webkit-box-shadow: 0 6px 12px #0000002f;
  box-shadow: 0 6px 12px #0000002f;
}

.logo-header {
  top: 20px;
  left: 20px;
  max-width: 40vw;
}

.header-btn:hover {
  color: #0bc268;
}
.user-header {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 20px;
  z-index: 1;
}
.header-text {
  font-size: 14px;
  font-weight: 400;
  text-align: right;
  padding-right: 15px;
}
.menu-dropdown .header-btn {
  cursor: pointer;
  transition: all;
  transition-duration: 0.3s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 14px;
  color: #464646;
}
.menu-dropdown-text {
  padding-left: 10px;
}
#HeaderMenuBtn {
  background: none;
  border: none;
  color: #464646;
  display: flex;
  align-items: center;
}
#HeaderMenuBtn::after,
#HeaderMenuBtn::before {
  background: none;
  content: none;
}

.form-check-label {
  font-size: 12px;
}

.form-check-input:checked {
  background-color: #0bc268;
  border-color: #0bc268;
}

.form-check-input:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  background-color: #0bc268;
  color: #fff;
  font-size: 24px;
}

.accordion-button {
  color: #fff;
  background-color: #0bc268;
  font-size: 24px;
}

p {
  font-weight: 400;
}

p.bankAccountNumber {
  font-size: 16px;
}

.unactiveIcon {
  filter: brightness(0) saturate(100%) invert(80%) sepia(3%) saturate(557%) hue-rotate(161deg) brightness(93%)
    contrast(85%);
}
.activeIcon {
  filter: brightness(0) saturate(100%) invert(41%) sepia(5%) saturate(1566%) hue-rotate(180deg) brightness(98%)
    contrast(83%);
}

.greenIcon {
  filter: brightness(0) saturate(100%) invert(52%) sepia(53%) saturate(3271%) hue-rotate(117deg) brightness(102%)
    contrast(91%);
}

.redIcon {
  filter: brightness(0) saturate(100%) invert(28%) sepia(53%) saturate(4510%) hue-rotate(338deg) brightness(78%)
    contrast(100%);
}

.yellowIcon {
  filter: brightness(0) saturate(100%) invert(94%) sepia(35%) saturate(1904%) hue-rotate(359deg) brightness(103%)
    contrast(93%);
}

.unactiveText {
  color: #b0b6ba;
}

.btn-danger {
  background-color: #c82333 !important;
  border-color: #c82333 !important;
}

.btn-danger:hover {
  background-color: #a11d2a !important;
  border-color: #a11d2a !important;
}

.btn-danger:after {
  background-color: #c82333 !important;
  border-color: #c82333 !important;
}

.btn-secondary {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.btn-secondary:hover {
  background-color: #5a6268 !important;
  border-color: #545b62 !important;
}

.btn-secondary:after {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.inputNumberHideArrow {
  /* Chrome, Safari, Edge, Opera */
  -webkit-appearance: none;
  margin: 0;

  /* Firefox */
  -moz-appearance: textfield;
}

.greenColor {
  color: #0bc268;
}

.redColor {
  color: #c82333;
}
.main-nav-body .nav-link {
  color: #fff;
  font-weight: 400;
}
body .offcanvas {
  overflow-y: auto;
}
body .offcanvas::-webkit-scrollbar {
  width: 5px;
}

body .offcanvas::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  width: 6px;
}

body .offcanvas::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.nav-link-icon {
  margin-right: 10px;
  min-width: 25px;
}
.circleK .accordion {
  border-radius: 10px !important;
  padding: 0;
}
.circleK .accordion-item {
  border-radius: 10px !important;
}
.circleK .accordion-collapse {
  border-radius: 10px !important;
}
.circleK .accordion-button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  font-size: 20px;
  justify-content: space-between;
}
.circleK .accordion-button::after {
  display: none;
}
.circleK .accordion-header,
.circleK .accordion-item {
  border: none;
}
.circleK .active .accordion-button {
  background: #0bc268 !important;
}

.circleK .blocked .accordion-button {
  background: #c82333 !important;
}
.circleK .inactive .accordion-button {
  background: #00635c !important;
}
.circleK .deleted .accordion-button {
  background: #626d80 !important;
}
.circleK .status-in-header {
  color: #fff !important;
}

.circleK .accordion-button:focus {
  border: none;
  box-shadow: none;
}

.circleK .circleK-cost .accordion,
.circleK .circleK-payment .accordion {
  border-radius: 10px !important;
  background: #fff;
}
.circleK .circleK-cost .accordion-button,
.circleK .circleK-payment .accordion-button {
  background: #fff;
  color: #0bc268;
  border-bottom: 1px solid #626d80;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.circleK .circleK-cost .card,
.circleK .circleK-payment .card {
  border: none;
  background: none;
}
.circleK .circleK-cost .accordion-item,
.circleK .circleK-payment .accordion-item {
  border-radius: 10px !important;
  padding: 0;
}
.circleK .accordion-button svg {
  transform: rotate(-90deg);
  transition: all;
  transition-duration: 0.5s;
}
.circleK .accordion-button.collapsed svg {
  transform: rotate(0);
  transition: all;
  transition-duration: 0.5s;
}
.circleK .circleK-payment .accordion-button.collapsed {
  border: none;
  border-radius: 10px;
}

.viewer .rpv-core__inner-page {
  background: none;
}

.h4 {
  font-size: 1.1em;
}

input.form-control {
  font-size: 14px;
}

.nav-link-text {
  line-height: 1em;
  padding-right: 0.25rem !important;
}
#News .accordion-header button {
  background-image: linear-gradient(to right, rgb(0, 99, 92), rgb(11, 194, 104));
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  padding: 10px;
}
#News .accordion-header {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
#News .accordion-item {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
#News .accordion-button:focus {
  border: none;
  box-shadow: none;
}

#MainMenu .accordion {
  background: none !important;
  border: none;
  padding: 0;
  width: 100%;
  box-shadow: none;
}
#MainMenu .accordion * {
  background: none !important;
  border: none;
  padding: 0;
  box-shadow: none;
}

#MainMenu .accordion .accordion-body {
  background: rgba(0, 0, 0, 0.1) !important;
  padding-left: 20px;
}
#MainMenu .accordion-header {
  padding: 0;
}

#MainMenu .accordion-button {
  color: #00685c !important;
  background: #fff !important;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
#MainMenu .accordion-button.collapsed {
  background: none !important;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

#MainMenu .accordion-button div {
  color: #00685c !important;
  font-weight: 600;
}
#MainMenu .accordion-button.collapsed div {
  color: #fff !important;
  font-weight: 500;
}

#MainMenu .accordion .accordion-body .nav-item {
  font-size: 18px;
}
#MainMenu .accordion .accordion-body .nav-item span {
  font-size: 18px !important;
}

#MainMenu .accordion-button .caret svg {
  transform: rotate(90deg);
  color: #00685c !important;
  transition: all;
  transition-duration: 0.5s;
}
#MainMenu .accordion-button.collapsed .caret svg {
  transform: rotate(0);
  transition: all;
  transition-duration: 0.5s;
  color: #fff !important;
}
#MainMenu .accordion-button::after {
  display: none;
}

#Loans .form-label {
  font-size: 14px;
  margin: 0;
  margin-top: 2px;
  font-weight: 300;
}
#pagination {
  margin-top: 10px;
}
#pagination ul {
  display: flex;
  align-items: center;
  margin: 0;
}
#pagination ul li {
  padding: 10px;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 50%;
  color: #0bc268;
}
#pagination ul li.selected {
  background: #0bc268;
  color: #fff;
}
#pagination ul li.selected a {
  color: #fff;
}
#pagination ul li a {
  text-decoration: none;
  color: #0bc268;
}

#loanValueInput {
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
}
#loanValueInput::-webkit-inner-spin-button,
#loanValueInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.Toastify__toast-body {
  white-space: pre-line;
}
.approvals-link {
  color: #0bc268;
  cursor: pointer;
  text-decoration: underline;
  transition-duration: 0.2s;
  transition-property: all;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
}
.approvals-link:hover {
  color: #414957;
  text-decoration: none;
}

.approval-pre-content p,
.approval-content p {
  font-weight: 500;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .btn-mobile-full-width {
    width: 100%;
  }
}

.discountCodes .accordion-button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  font-size: 20px;
  justify-content: space-between;
}
.discountCodes .accordion-button::after {
  display: none;
}
.discountCodes .accordion-button:focus {
  border: none;
  box-shadow: none;
}
.discountCodes .discountCodes-instructions .accordion {
  border-radius: 10px !important;
  background: #fff;
}
.discountCodes .discountCodes-instructions .accordion-button {
  background: #fff;
  color: #00635c;
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  width: 100%;
}
.discountCodes .birthdayCode-instruction .accordion-button {
  background: #fff;
  color: #fb7a32;
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  width: 100%;
}
.discountCodes .discountCodes-instructions .accordion-body {
  background: #fff;
}
.discountCodes .discountCodes-instructions .card {
  border: none;
  background: none;
}
.discountCodes .discountCodes-instructions .accordion-item {
  border-radius: 10px !important;
  padding: 0;
}
.discountCodes .accordion-button svg {
  transform: rotate(90deg);
  transition: all;
  transition-duration: 0.5s;
}
.discountCodes .accordion-button.collapsed svg {
  transform: rotate(0);
  transition: all;
  transition-duration: 0.5s;
}
.discountCodes .discountCodes-instructions .accordion-button.collapsed {
  border: none;
  border-radius: 10px;
}
.discountCode-bank {
  display: flex;
  align-items: center;
}
.discountCode-bank .form-check-input {
  width: 2em;
  height: 2em;
  border-radius: 50%;
}

#popover-basic .popover-header {
  background: linear-gradient(to right, rgb(0, 99, 92), rgb(11, 194, 104));
  color: #fff;
}
#popover-basic .popover-header::before {
  content: '';
  display: none;
}

#popover-basic .popover-arrow {
  display: none;
}

#popover-basic {
  border-radius: 10px;
  border: none;
  overflow: hidden;
  box-shadow: -1px 3px 24px -17px rgba(0, 0, 0, 0.3);
}

.cursor-pointer {
  cursor: pointer;
}

.btn-close {
  background: transparent
    url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e')
    center/1em auto no-repeat;
}

.cinemaVouchers .cinemaVouchers-instructions .card {
  border: none;
  background: none;
}

.my-accordion .accordion-button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  font-size: 20px;
  justify-content: space-between;
}
.my-accordion .accordion-button::after {
  display: none;
}
.my-accordion .accordion-button:focus {
  border: none;
  box-shadow: none;
}
.my-accordion {
  border-radius: 10px !important;
  background: #fff;
}
.my-accordion .accordion-button {
  background: #fff;
  color: #00635c !important;
  display: flex;
  justify-content: space-between;
  font-size: 18px !important;
  width: 100%;
}

.my-accordion .accordion-body {
  background: #fff;
}
.my-accordion .card {
  border: none;
  background: none;
}
.my-accordion .accordion-item {
  border-radius: 10px !important;
  padding: 0;
}
.my-accordion .accordion-button svg {
  transform: rotate(90deg);
  transition: all;
  transition-duration: 0.5s;
}
.my-accordion .accordion-button.collapsed svg {
  transform: rotate(0);
  transition: all;
  transition-duration: 0.5s;
}
.my-accordion .accordion-button.collapsed {
  border: none;
  border-radius: 10px !important;
}
.my-radio-button {
  display: flex;
  align-items: center;
}
.my-radio-button input[type='radio'] {
  width: 24px;
  height: 24px;
}
.my-radio-button input[type='radio']:focus {
  outline: none;
  outline-offset: none;
  background-image: none;
  background-color: #0bc268;
}
.my-radio-button input[type='radio']:checked {
  background-image: none;
  outline-offset: none;
  background-color: #0bc268;
}
.my-radio-button label {
  padding-left: 10px;
  padding-right: 10px;
}

.my-radio-button input[type='radio'] {
  position: relative;
}
.my-radio-button input[type='radio']::before {
  content: '';
  position: absolute;
  border-radius: 50%;
  background: #fff;
  width: 12px;
  height: 12px;
  top: 5px;
  left: 5px;
  opacity: 1;
  pointer-events: none;
}

#tooltip-disease-extension .react-tooltip-arrow,
#tooltip-sport-extension .react-tooltip-arrow {
  transform: translateX(-10px) rotate(225deg);
}

//KL
.right-sidebar .mobile-toggle-label img {
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1, 1);
}
.right-sidebar {
  position: static;
  left: 0;
  width: 300px;
  top: 150px;
  z-index: 10;
  font-size: 0.8rem;
}

.right-sidebar > * {
  padding: 24px 15px 18px 27px;
  background-color: #ffffff;
  box-shadow: 10px 20px 40px 0 rgba(0, 0, 0, 0.1);
}

.right-sidebar > * strong {
  font-weight: 600;
}

.right-sidebar > * .summary {
  padding-top: 8px;
  margin-top: 12px;
  border-top: 1px solid #fff;
}

.right-sidebar > * p {
  white-space: nowrap;
  margin: 0;
}

.right-sidebar > * .sidebar-header {
  margin-bottom: 6px;
}

.right-sidebar.sidebar-small {
  max-width: 172px;
}

.right-sidebar.sidebar-small > * {
  padding-left: 18px;
  padding-right: 12px;
}

.right-sidebar .products-list {
  max-height: 40vh;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.right-sidebar .mobile-toggle-label img {
  position: absolute;
  top: 50%;
  right: 0;
  height: 12px;
  margin-top: -6px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.right-sidebar .mobile-toggle-checkbox:checked ~ .sidebar-header .mobile-toggle-label strong {
  display: none;
}

.right-sidebar .mobile-toggle-checkbox:checked ~ .sidebar-header .mobile-toggle-label img {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  position: relative;
}

.right-sidebar .mobile-toggle-checkbox:checked ~ .mobile-toggle-content {
  display: none;
}
.hidden-on-desktop {
  display: none;
}
@media (max-width: 768px) {
  .right-sidebar {
    top: auto;
    position: fixed;
    bottom: 0;
    z-index: 20;
    width: 100%;
    border-radius: 22px 22px 0 0;
  }

  .right-sidebar.sidebar-small {
    max-width: 100%;
  }

  .right-sidebar > * {
    border-radius: 0;
    padding-right: 28px;
  }

  .right-sidebar .summary {
    padding-right: 28px;
  }

  .right-sidebar .mobile-toggle-label img {
    right: 28px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .right-sidebar .mobile-toggle-checkbox:checked ~ .mobile-toggle-content {
    max-height: 50vh;
    display: block;
  }

  .right-sidebar .mobile-toggle-checkbox:checked ~ .sidebar-header .mobile-toggle-label strong {
    display: inline-block;
  }

  .right-sidebar .mobile-toggle-checkbox:checked ~ .sidebar-header .mobile-toggle-label img {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    position: absolute;
  }
  .chevron-right,
  .chevron-left {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .hidden-on-desktop {
    display: block;
  }
}

//cashback2
.cashback2 .accordion-button {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  font-size: 20px;
  justify-content: space-between;
}
.cashback2 .accordion-button::after {
  display: none;
}
.cashback2 .accordion-button:focus {
  border: none;
  box-shadow: none;
}
.cashback2 .cashback2-instructions .accordion {
  border-radius: 10px !important;
  background: #fff;
  border: none;
}
.cashback2 .cashback2-instructions .accordion-item {
  border-radius: 10px !important;
  background: #fff;
  border: none;
}
.cashback2 .cashback2-instructions .accordion-button {
  background: #fff;
  color: #00635c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.cashback2 .cashback2-instructions .accordion-body {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029;
  padding: 20px 0;
}
.cashback2 .cashback2-instructions .card {
  border: none;
  background: none;
}
.cashback2 .cashback2-instructions .accordion-item {
  border-radius: 10px !important;
  padding: 0;
}
.cashback2 .accordion-button svg {
  transform: rotate(-180deg);
  transition: all;
  transition-duration: 0.5s;
}
.cashback2 .accordion-button.collapsed svg {
  transform: rotate(0);
  transition: all;
  transition-duration: 0.5s;
}
.cashback2 .cashback2-instructions .accordion-button.collapsed {
  border: none;
  border-radius: 10px;
}
